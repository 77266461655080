@import '../vars';

.factoid {
  margin: 0;

  &__number {
    font-family: $font-accent;
    font-weight: normal;
    display: block;
    font-size: 50px;
    line-height: 40px;
  }

  &__caption {
    font-size: 11px;
    line-height: 10px;
    max-width: 140px;
  }
}
