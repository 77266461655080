@import "../vars";

.form {
  max-width: 100vw;
  overflow: hidden;
  padding: 55px 0 70px;
  position: relative;
  margin: -35px 0 -9px;
  z-index: 3;

  @include w-from($screen-tablet) {
    padding: 90px 0;
  }

  @include w-from($screen-laptop) {
    padding: 120px 0;
    margin: -78px 0 50px;
  }

  @include w-from($screen-desktop) {
    padding: 140px 0;
  }

  @include w-from($screen-desktop-wide) {
    padding: 160px 0;
  }

  @include w-from($screen-desktop-ultrawide) {
    padding: 240px 0;
  }

  .input {
    transform: $flag-skew;
  }

  .buttonSend {
    position: absolute;
    bottom: 9%;
    right: 8%;
    z-index: 4;
    transform: $flag-skew;

    @include w-from($screen-tablet) {
      right: 5.8%;
      bottom: 12.5%;
    }

    @include w-horizontal-phone {
      bottom: 15%;
      right: 3%;
    }

    @include w-from($screen-laptop-wide) {
      bottom: 14%;
      right: 4%;
    }

    @include w-from($screen-desktop) {
      bottom: 19%;
    }

    @include w-from($screen-desktop-wide) {
      bottom: 23%;
      right: 4.5%;
    }

    @include w-from($screen-desktop-ultrawide) {
      bottom: 28%;
    }
  }

  .submittedFlag {
    z-index: 3;
    position: absolute;
    will-change: transform;
    transition: .9s cubic-bezier(.1, .6, .125, 1);
    transform: scale(3.5);
    left: 12vw;
    top: 50%;

    @include w-from($screen-tablet) {
      top: 33%;
      transform: scale(2.55);
    }

    @include w-from($screen-laptop) {
      left: 33vw;
      top: 49%;
      transform: scale(4.5);
    }

    @include w-from($screen-desktop) {
      top: 52%;
      left: 27vw;
    }

    &.is__pinned {
      transform: scale(1);
      left: 64%;
      top: 15%;

      @include w-from($screen-tablet) {
        left: 67%;
        top: 8%;
      }

      @include w-from($screen-laptop) {
        left: 56%;
        top: 21%;
      }

      @include w-from($screen-desktop) {
        top: 16%;
        left: 55%;
      }

      @include w-from($screen-desktop-wide) {
        top: 22%;
        left: 71%;
      }
    }
  }
}
