@import '../vars';

$empty-input-caret-shift: 5px;

@keyframes invalid-pulse {
  26%,
  74% {
    border-color: #000;
  }
  0%,
  25%,
  75%,
  100% {
    border-color: $link-hover-color;
  }
}

.input {
  --inputField: 5vw;
  font-family: $font-accent;
  position: relative;
  font-size: 212px;
  line-height: 156px;
  text-transform: uppercase;
  white-space: nowrap;
  height: 182px;
  transition: $fast-transition;

  &,
  &__in,
  &__placeholder,
  &__value,
  & input {
    cursor: url('ibeam.svg') 20.5 47, text;
  }

  &__placeholder,
  & input {
    @include w-to($screen-laptop) {
      padding: 0 0 4px;
    }
  }

  &:not(.is__filled):not(.is__focused):before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    pointer-events: none;
  }

  &.is__focused,
  &.is__filled {
    background: #fff;
  }

  &:not(.is__valid) {
    .input__in:after {
      animation: invalid-pulse 1.5s linear;
    }
  }

  &:hover:not(.is__focused):not(.is__filled) {
    background: rgba(255, 255, 255, 0.9);
  }

  &.is__focused:not(.is__filled) input {
    padding-left: calc(var(--inputField) - #{$empty-input-caret-shift});
  }

  &.is__overflowed input {
    padding-left: 0;
  }

  @include w-to($screen-laptop) {
    --inputField: 30px;
    font-size: 79px;
    line-height: 70px;
    height: 70px;
  }

  input,
  &__placeholder,
  &__value, {
    font: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    text-transform: inherit;
  }

  input {
    position: absolute;
    left: 0;
    right: 0;
    top: -.125em;
    bottom: -.1em;
    z-index: 1;
    width: 100%;
    background: transparent;
    color: black;
    border: none;
    border-radius: 0;
    caret-color: #000;
    outline: none;
    margin: 0;
    padding: 0 0 0 var(--inputField);
    box-sizing: border-box;
  }

  &__in {
    position: relative;
    height: 100%;
    padding: 14px var(--inputField) $page-field-small;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @include w-to($screen-laptop) {
      padding-top: 0;
      padding-bottom: 0;
    }

    &:after {
      content: '';
      border-bottom: 1px solid #000;
      position: absolute;
      left: var(--inputField);
      right: var(--inputField);
      bottom: 0;

      .is__focused & {
        border-width: 2px;
      }

      @include w-to($screen-laptop) {
        transform: scaleY(.5);
      }
    }

    input:focus ~ & {
      box-shadow: inset 0 1px 11px rgba(0, 0, 0, 0.25);

      @include w-to($screen-laptop) {
        box-shadow: inset 0 0.375px 4px rgba(0, 0, 0, 0.25);
      }

      .input__placeholder {
        -webkit-text-stroke-width: 2px;

        @include w-to($screen-laptop) {
          -webkit-text-stroke-width: 1px;
        }
      }
    }
  }

  &__placeholder {
    color: transparent;
    -webkit-text-stroke: 1px black;

    @include w-to($screen-laptop) {
      -webkit-text-stroke-width: .5px;
    }
  }

  /* Оставляю для проверки ширины текста, когда нужно сбросить левую границу инпута */
  &__value {
    color: transparent;
  }
}
