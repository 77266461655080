@import '../vars';

@mixin button-hover-styles {
  filter: drop-shadow(6px 4px 0 $link-hover-color);
  fill: #002aff;

  @include w-to($screen-laptop) {
    filter: drop-shadow(4px 3.5px 0 $link-hover-color);
  }
}

.buttonSend {
  position: relative;
  padding: 15px 7px 7px;
  margin: -15px -7px -7px;
  width: 245px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include w-to($screen-laptop) {
    width: 164px;
    padding: 0;
    margin: 0;
  }

  /* Почему-то объединённое правило не работает в Сафари :-/ */
  &:hover svg {
    @include button-hover-styles;
  }

  button:focus-visible + svg, {
    @include button-hover-styles;
  }

  button,
  button:active {
    color: transparent;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    border-radius: 0;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  svg {
    display: block;
    width: 100%;
    filter: drop-shadow(5px 4px 0 $link-color);
    fill: $link-hover-color;

    @include w-to($screen-laptop) {
      filter: drop-shadow(4px 3.5px 0 $link-color);
    }
  }

  .iconTextSend {
    max-width: 198px;

    @include w-to($screen-laptop) {
      max-width: 140px;
    }
  }

  .iconTextSent {
    max-width: 245px;
  }
}
