@import '../vars';

.factoids {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transform: $flag-skew;

  @include w-to($screen-tablet) {
    flex-direction: row;
  }

  &__divider {
    font-size: 11px;
    line-height: 10px;

    @include w-to($screen-tablet) {
      display: none;
    }
  }

  &__group,
  &__divider,
  .factoid {
    &:not(:last-child) {
      margin-bottom: 9px;
    }
  }

  &__group:not(:last-child) {
    @include w-to($screen-tablet) {
      margin-bottom: 0;
      margin-right: 10px;
    }
  }
}
