@import "src/vars";

@keyframes flag-section-1 {
  from { transform: translate(-100%, 1%); }
  to { transform: translate(0, 0); }
}

@keyframes flag-section-2 {
  from { transform: translate(0, 0); }
  to { transform: translate(100%, -3%); }
}

@keyframes flag-section-3 {
  from { transform: translate(100%, -3%); }
  to { transform: translate(200%, -5%); }
}

@keyframes flag-section-1-in {
  from { transform: translateX(50%); }
  to { transform: translateX(0); }
}

@keyframes flag-section-2-in {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
}

@keyframes flag-section-3-in {
  from { transform: translateX(-50%); }
  to { transform: translateX(-100%); }
}

.flag {
  position: relative;
  display: inline-block;
  font-family: $font-accent;
  text-transform: uppercase;
  font-size: 45px;
  line-height: .8;
  transform: $flag-skew;
  transition: opacity .1s ease-in;

  &:not(.is__ready) {
    opacity: 0;

    .flag__section,
    .flag__sectionIn {
      animation-play-state: paused !important;
    }

    .flag__section:first-child {
      overflow: visible;
    }

    .flag__section:not(:first-child) {
      display: none;
    }
  }

  &__section,
  &__sectionIn {
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    will-change: transform;
  }

  &__row {
    display: block;
    white-space: nowrap;
  }

  &__sectionIn {
    display: inline-block;
  }

  &__section {
    width: 50%;
    overflow: hidden;
    position: relative;
    top: -.2em;
    bottom: -.2em;
    transform-origin: top left;

    &:not(:first-child) {
      position: absolute;
      left: 0;
      pointer-events: none;
    }

    .flag__sectionIn {
      padding: .2em 0;
    }
  }

  @for $i from 1 through 3 {
    .flag__section:nth-child(#{$i}) { animation-name: flag-section-#{$i};}
    .flag__section:nth-child(#{$i}) .flag__sectionIn { animation-name: flag-section-#{$i}-in;}
  }
}
