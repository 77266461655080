@import './vars';

h2,
p {
  margin: 0;
}

h2 {
  font-size: 11px;
  line-height: 13px;

  @include w-from($screen-laptop) {
    font-size: 16px;
    line-height: 12px;
    letter-spacing: -0.5px;
  }
}

section {
  position: relative;

  &:not(:last-child) {
    @include border-bottom;

    margin-bottom: 20px;
    padding-bottom: 25px;

    @include w-to($screen-laptop) {
      padding-bottom: 15px;
      margin-bottom: 10px;
    }
  }

  h2 {
    display: inline-block;
    margin-right: 6px;
    white-space: nowrap;
    margin-top: 5px;

    @include w-from($screen-laptop) {
      margin-top: 15px;
      margin-right: 13px;
    }
  }

  p {
    text-indent: 15px;

    @include w-from($screen-laptop) {
      text-indent: 35px;
      letter-spacing: -0.5px;
    }
  }

  h2 + p {
    display: inline;
    text-indent: 10px;

    @include w-to($screen-laptop) {
      text-indent: 0;
    }
  }

  p:only-child {
    text-indent: 0;
  }
}
