@import './vars';
@import './typography';

@font-face {
  font-family: 'Druk';
  src: url(/assets/Druk-Super-Web.woff2) format("woff2");
}

body {
  font-family: "Helvetica Neue", sans-serif;
  margin: 0;
  padding: 0 0 20px;
  position: relative;
  font-size: 25px;
  line-height: 35px;
  overflow-x: hidden;
  transition: 0s;

  @include w-to($screen-laptop) {
    font-size: 15px;
    line-height: 21px;
    padding-bottom: 20px;
  }
}

.footer {
  display: block;
  margin-top: 150px;
  font-size: 16px;
  line-height: 20px;

  @include w-to($screen-laptop) {
    font-size: 12px;
    line-height: 15px;
    padding: 0 10px;
    margin-top: 40px;

    & > p {
      max-width: 450px;
    }
  }

  @include w-from($screen-laptop) {
    @include wrapper;
  }

  & > p a {
    white-space: nowrap;
    color: $link-color;

    &:hover {
      color: $link-hover-color;
    }
  }

  .bureauCopy {
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 8px;

    @include w-from($screen-laptop) {
      display: none;
    }

    svg {
      max-width: 114px;
      margin-right: 4px;
    }

    p {
      margin-top: 1px;
    }
  }

  .credits {
    margin-top: 10px;
  }
}

.wrapper {
  @include wrapper;
}

.wrapper p a {
  color: $link-color;

  &:hover {
    color: $link-hover-color;
  }
}

a {
  text-decoration: none;
  color: inherit;
  position: relative;
}

a,
label {
  &:after {
    content: '';
    position: absolute;
    left: -$link-extend-area;
    right: -$link-extend-area;
    top: -$link-extend-area;
    bottom: -$link-extend-area;
  }

  &:not(:hover) {
    transition: .04s;
  }

  &:hover {
    color: $link-hover-color;

    svg path {
      fill: $link-hover-color;
    }
  }

  svg path {
    transition: inherit;
  }
}

svg {
  g,
  path {
    fill: inherit;
  }
}

.header {
  padding: 8px 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10;

  @include w-from($screen-laptop) {
    padding: 15px $page-field-small 0;
  }

  .link {
    margin-top: -40px;

    &:after {
      right: 0;
    }
  }
}

.logo {
  display: inline-block;
}

.link {
  font-size: 20px;
  line-height: 22px;

  font-weight: bold;
  color: $link-color;

  &:hover {
    color: $link-hover-color;
  }

  @include w-to($screen-laptop) {
    font-size: 14px;
    line-height: 20px;
    margin-top: -45px;
    margin-right: 0;
  }
}

.bureauCopy {
  display: flex;
  font-size: 16px;
  line-height: 18px;

  &.is__compact p {
    opacity: 0;
  }

  svg {
    display: inline-block;
    margin-right: 8px;
  }

  p {
    display: none;
    margin-top: -3px;
    transition: .1s;
  }
}

.stickers {
  &:hover {
    svg path {
      fill: #fff;
    }

    .stickers__bg {
      fill: $link-color;
    }
  }

  &:after {
    content: none;
  }

  &__bg {
    fill: #000;
  }

  svg {
    display: block;
    width: 150px;
    height: auto;
    fill: #fff;

    @include w-to($screen-laptop) {
      width: 90px;
    }
  }
}

.stickyShaft {
  position: absolute;
  left: $page-field-small;
  top: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  pointer-events: none;

  &.is__right {
    left: auto;
    right: $page-field-small;
    bottom: 24px;
  }

  @include w-to($screen-laptop) {
    &:not(.is__right) {
      display: none;
    }
  }

  @include w-range($footer-breakpoint, 560px) {
    &.is__right {
      bottom: 165px;
    }
  }

  @include w-from($screen-tablet) {
    bottom: 22px;
  }

  @include w-from($screen-laptop) {
    bottom: 153px;
  }

  @include w-from($screen-laptop-wide) {
    bottom: 133px;
  }

  @include w-from($screen-desktop) {
    bottom: 113px;
  }

  .bureauCopy,
  .stickers {
    pointer-events: auto;
    position: sticky;
    bottom: 24px;
  }
}

.pinnedContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 3;
  pointer-events: none;
  overflow: hidden;

  @include w-to($screen-laptop) {
    height: 50vh;
    top: 30px;
  }

  @include w-horizontal-phone {
    height: 100vh;
    top: 0;
  }
}

.pinnedLabel {
  --pinnedLabelField: #{$page-field};
  font-size: 20px;
  line-height: 22px;
  white-space: nowrap;
  font-weight: bold;
  position: absolute;
  top: 50%;
  z-index: 1;
  user-select: none;
  writing-mode: vertical-lr;
  transform: scale(-1) translateY(50%);
  pointer-events: none;

  &[href] {
    pointer-events: all;
    touch-action: manipulation;
  }

  &:not(.is__right) {
    left: var(--pinnedLabelField);
  }

  &.is__right {
    right: calc(var(--pinnedLabelField) + 4px);
  }

  @include w-to($screen-laptop) {
    --pinnedLabelField: 2px;
    font-size: 14px;
    line-height: 20px;
  }
}

.credits {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @include w-to($footer-breakpoint) {
    flex-direction: column;
  }

  &__item {
    &:not(:last-child) {
      margin-right: 20px;

      @include w-to($footer-breakpoint) {
        margin-right: 0;
        margin-bottom: 5px;
      }
    }
  }
}
