@import "../vars";

.columns {
  @include wrapper;
  margin-top: -16px;
  margin-bottom: 26px;

  @include w-to($screen-tablet) {
    margin-top: 0;
    box-sizing: border-box;
    max-width: 100vw;
    margin-bottom: 10px;
  }

  &__in {
    @include border-bottom;
    padding-bottom: 60px;

    @include w-to($screen-tablet) {
      padding-bottom: 15px;
    }
  }

  &__row {
    &:not(:last-child) {
      margin-bottom: 40px;
    }

    @include w-to($screen-tablet) {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }

    @include w-from($screen-tablet) {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-column-gap: 68px;
      justify-content: center;
    }

    &.is__tagged {
      display: grid;
      grid-template-columns: repeat(5, auto);
      grid-template-rows: auto auto;
      grid-gap: 20px;

      @include w-to($screen-tablet) {
        grid-template-columns: repeat(10, auto);
        grid-template-rows: auto;

        width: 100%;
        overflow: scroll;
        padding: 20px $page-field;
        margin: -20px #{-$page-field};
        -webkit-overflow-scrolling: touch;
        justify-content: flex-start;
        grid-gap: 20px 15px;

        /* HACK: hide scrollbar */
        clip-path: inset(0 0 15px 0);

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          z-index: 1;
          left: -$page-field;
          width: $page-field;
          background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
          pointer-events: none;
        }

        &:after {
          left: auto;
          right: -$page-field;
          background: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
        }
      }
    }
  }

  .factoids {
    @include w-to($screen-laptop) {
      margin-top: 12px;
    }

    @include w-from($screen-tablet) {
      margin-top: -420%;
    }

    @include w-from($screen-laptop) {
      margin-top: -200%;
      margin-right: -10px;
    }
  }
}
