.phrase {
  font: inherit;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &,
  &:hover {
    color: #000;
  }

  span {
    white-space: nowrap;
  }
}
