$font-accent: 'Druk', sans-serif;

$link-color: #002aff;
$link-hover-color: #FF0000;

$page-field-small: 12px;
$page-field: 10px;
$wrapper-compact-width: 640px;
$wrapper-standard-width: 680px;
$wrapper-max-width: 900px;

$link-extend-area: 7px;

$screen-tablet: 700px;
$screen-laptop: 961px;
$screen-laptop-wide: 1000px;
$screen-desktop: 1400px;
$screen-desktop-wide: 1900px;
$screen-desktop-ultrawide: 2200px;

$footer-breakpoint: 400px;

$fast-transition: .04s;
$flag-skew: skew(0, -9deg);

@mixin w-from($screen) {
  @media (min-width: $screen) {
    @content;
  }
}

@mixin w-to($screen) {
  @media (max-width: $screen - 1px) {
    @content;
  }
}

@mixin w-range($screenFrom, $screenTo) {
  @media (min-width: $screenFrom) and (max-width: $screenTo - 1px) {
    @content;
  }
}

@mixin w-horizontal-phone() {
  @media (max-width: 821px) and (orientation: landscape) {
    @content;
  }
}

@mixin wrapper {
  width: 100%;
  max-width: $wrapper-compact-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: $page-field;
  padding-right: $page-field;
  box-sizing: content-box;

  @include w-to($screen-laptop) {
    box-sizing: border-box;
  }

  @include w-from($screen-laptop-wide) {
    max-width: $wrapper-standard-width;
  }

  @include w-from($screen-desktop) {
    max-width: $wrapper-max-width;
  }
}

@mixin border-bottom {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 1px solid;
    transform: scaleY(.5);
  }
}
