@import "../vars";

@keyframes share-button-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.submittedFlag {
  position: relative;
  cursor: default;

  &.is__pinned {
    .shareButton {
      animation: share-button-in .1s forwards;
    }
  }

  &__in {
    transform: $flag-skew;
  }

  .flag {
    display: block;
    font-size: 24px;
    transform: none;

    @include w-from($screen-tablet) {
      font-size: 34px;
    }

    @include w-from($screen-laptop) {
      font-size: 50px;
    }
  }

  .shareButton {
    position: absolute;
    top: 100%;
    opacity: 0;
    margin-top: -5px;
  }
}
