@import "../vars";

.flags {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 0;
  pointer-events: none;

  @include w-from($screen-desktop-ultrawide) {
    left: 12vw;
    right: 12vw;
    overflow: visible;
    max-height: 100%;
  }

  &__top,
  &__bottom {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
  }

  .flag {
    position: absolute;
  }
}

.flags .flag {
  @include w-to($screen-laptop) {
    &:nth-child(n+21) { display: none; }

    &:nth-child(1) {top: 8.71%;left: 7.92%;font-size: 38px;}
    &:nth-child(2) { display: none }
    &:nth-child(3) {top: 2.08%;left: 30.35%;font-size: 13px;}
    &:nth-child(4) {top: 10.76%;left: 47.56%;font-size: 27px;}
    &:nth-child(5) {top: 14.57%;left: 88.00%;font-size: 16px;}
    &:nth-child(6) {top: 1.66%;left: 62.39%;font-size: 25px;}
    &:nth-child(7) {top: 5.03%;left: 5.37%;font-size: 25px;}
    &:nth-child(8) {top: 23.15%;left: 76.13%;font-size: 18px;}
    &:nth-child(9) {top: 18.04%;left: 80.30%;font-size: 19px;}
    &:nth-child(10) {top: 6.61%;left: 27.57%;font-size: 20px;}
    &:nth-child(11) {top: 16.13%;left: 2.17%;font-size: 18px;}
    &:nth-child(12) {top: 27.27%;left: -0.37%;font-size: 18px;}
    &:nth-child(13) {top: 27.48%;left: 87.61%;font-size: 24px;}
    &:nth-child(14) {top: 59.21%;left: 87.95%;font-size: 34px;}
    &:nth-child(15) {top: 58.46%;left: -0.44%;font-size: 14px;}
    &:nth-child(16) {top: 70.80%;left: -0.50%;font-size: 18px;}
    &:nth-child(17) {top: 81.05%;left: -0.50%;font-size: 9px;}
    &:nth-child(18) {top: 67.12%;left: 89.35%;font-size: 24px;}
    &:nth-child(19) {top: 72.72%;left: 92.00%;font-size: 17px;}
    &:nth-child(20) {top: 80.79%;left: 91.52%;font-size: 14px;}
  }

  @include w-to($screen-tablet) {
    &:nth-child(n+9) { display: none; }

    &:nth-child(1) {top: 7.5%;left: 11.51%;font-size: 22px;}
    &:nth-child(2) {top: 2.74%;left: 6.92%;font-size: 13px; display: block}
    &:nth-child(3) {top: 1.73%;left: 60.51%;font-size: 13px;}
    &:nth-child(4) {top: 4.47%;left: 12.88%;font-size: 13px;}
    &:nth-child(5) {top: 9.30%;left: 9.79%;font-size: 13px;}
    &:nth-child(6) {top: 0.71%;left: 46.14%;font-size: 13px;}
    &:nth-child(7) {top: 5.83%;left: 29.09%;font-size: 13px;}
    &:nth-child(8) {top: 7.31%;left: 67.56%;font-size: 13px;}
  }

  @include w-horizontal-phone() {
    &:nth-child(1) {
      left: 9%;
      top: 12.1%;
      font-size: 30px;
    }
    &:nth-child(2) {
      left: 12%;
      top: 9.3%;
      font-size: 19px;
    }
    &:nth-child(3) {
      left: 28%;
      top: 3%;
      font-size: 26px;
    }
    &:nth-child(4) {
      left: 9.5%;
      top: 5%;
      font-size: 13px;
    }
    &:nth-child(5) {
      left: 42%;
      top: 14%;
      font-size: 23px;
    }
    &:nth-child(6) {
      left: 60%;
      top: 1.5%;
      font-size: 15px;
    }
    &:nth-child(7) {
      left: 54.5%;
      top: 10.3%;
      font-size: 13px;
    }
    &:nth-child(8) {
      left: 91.5%;
      top: 17.4%;
      font-size: 15px;
    }
    &:nth-child(9) {
      left: 70%;
      top: 11.3%;
      font-size: 24px;
    }
    &:nth-child(10) {
      left: 38%;
      top: 7.1%;
      font-size: 25px;
    }
    &:nth-child(11) {
      left: 0;
      top: 20.2%;
      font-size: 18px;
    }
    &:nth-child(20) {
      left: 85%;
      top: 88%;
      font-size: 25px;
    }
  }

  @include w-from($screen-laptop) {
    &:nth-child(1) {top: 8.19%;left: 35.64%;font-size: 72px;}
    &:nth-child(2) {top: 11.53%;left: 2.75%;font-size: 40px;}
    &:nth-child(3) {top: 18.06%;left: 10.50%;font-size: 26px;}
    &:nth-child(4) {top: 13.88%;left: 37.77%;font-size: 20px;}
    &:nth-child(5) {top: 14.80%;left: 58.18%;font-size: 29px;}
    &:nth-child(6) {top: 8.3%;left: 7.1%;font-size: 45px;}
    &:nth-child(7) {top: 12.9%;left: 63.05%;font-size: 30px;}
    &:nth-child(8) {top: 22.73%;left: 73.00%;font-size: 38px;}
    &:nth-child(9) {top: 17.59%;left: 71.31%;font-size: 34px;}
    &:nth-child(10) {top: 0.56%;left: 47.16%;font-size: 20px;}
    &:nth-child(11) {top: 2.50%;left: 27.63%;font-size: 25px;}
    &:nth-child(12) {top: 5.76%;left: 11.09%;font-size: 27px;}
    &:nth-child(13) {top: 15.71%;left: 20.53%;font-size: 20px;}
    &:nth-child(14) {top: 20.01%;left: 84.89%;font-size: 22px;}
    &:nth-child(15) {top: 34.12%;left: 82.83%;font-size: 34px;}
    &:nth-child(16) {top: 40.75%;left: -0.30%;font-size: 36px;}
    &:nth-child(17) {top: 38.55%;left: 80.78%;font-size: 82px;}
    &:nth-child(18) {top: 64.44%;left: -0.30%;font-size: 62px;}
    &:nth-child(19) {top: 74.11%;left: 85.46%;font-size: 62px;}
    &:nth-child(20) {top: 87.62%;left: -0.17%;font-size: 38px;}
    &:nth-child(21) {top: 4.47%;left: 36.26%;font-size: 20px;}
    &:nth-child(22) {top: 2.83%;left: 3.08%;font-size: 19px;}
    &:nth-child(23) {top: 0.48%;left: 59.15%;font-size: 16px;}
    &:nth-child(24) {top: 25.06%;left: 2.1%;font-size: 29px;}
    &:nth-child(25) {top: 0.45%;left: 19.50%;font-size: 18px;}
    &:nth-child(26) {top: 29.73%;left: -0.32%;font-size: 32px;}
    &:nth-child(27) {top: 55.69%;left: 82.66%;font-size: 40px;}
    &:nth-child(28) {top: 52.46%;left: -0.08%;font-size: 34px;}
    &:nth-child(29) {top: 60.91%;left: 83.00%;font-size: 22px;}
    &:nth-child(30) {top: 88.64%;left: 83.40%;font-size: 41px;}
    &:nth-child(31) {top: 3.09%;left: 15.92%;font-size: 17px;}
    &:nth-child(32) {top: 21.58%;left: 2.08%;font-size: 24px;}
    &:nth-child(33) {top: 35.14%;left: 2.74%;font-size: 21px;}
    &:nth-child(34) {top: 49.64%;left: 87.67%;font-size: 23px;}
    &:nth-child(35) {top: 59.30%;left: 2.08%;font-size: 22px;}
    &:nth-child(36) {top: 71.33%;left: -0.22%;font-size: 27px;}
    &:nth-child(37) {top: 67.92%;left: 87.00%;font-size: 20px;}
    &:nth-child(38) {top: 80.13%;left: 1.25%;font-size: 18px;}
    &:nth-child(39) {top: 81.69%;left: 84.99%;font-size: 24px;}
    &:nth-child(40) {top: 47.67%;left: 1.83%;font-size: 18px;}
    &:nth-child(41) {top: 93.12%;left: 2.00%;font-size: 18px;}
    &:nth-child(42) {top: 27.32%;left: 80.71%;font-size: 40px;}
    &:nth-child(43) {top: 93.81%;left: 80.49%;font-size: 24px;}
  }

  @include w-from($screen-desktop) {
    &:nth-child(1) {top: 11.03%;left: 26.39%;font-size: 80px;}
    &:nth-child(2) {top: 14.74%;left: 2.44%;font-size: 40px;}
    &:nth-child(3) {top: 17.70%;left: 32.15%;font-size: 28px;}
    &:nth-child(4) {top: 19.48%;left: 17.94%;font-size: 20px;}
    &:nth-child(5) {top: 1.21%;left: 76.79%;font-size: 25px;}
    &:nth-child(6) {top: 1.41%;left: 59.49%;font-size: 25px;}
    &:nth-child(7) {top: 11.22%;left: 9.15%;font-size: 30px;}
    &:nth-child(8) {top: 26.55%;left: 87.06%;font-size: 20px;}
    &:nth-child(9) {top: 19.80%;left: 66.53%;font-size: 34px;}
    &:nth-child(10) {top: 0.91%;left: 45.00%;font-size: 20px;}
    &:nth-child(11) {top: 2.43%;left: 26.49%;font-size: 20px;}
    &:nth-child(12) {top: 6.03%;left: 6.64%;font-size: 27px;}
    &:nth-child(13) {top: 16.29%;left: 58.49%;font-size: 26px;}
    &:nth-child(14) {top: 23.02%;left: 76.07%;font-size: 47px;}
    &:nth-child(15) {top: 37.06%;left: 80.67%;font-size: 34px;}
    &:nth-child(16) {top: 41.99%;left: 2.06%;font-size: 36px;}
    &:nth-child(17) {top: 44.97%;left: 78.87%;font-size: 82px;}
    &:nth-child(18) {top: 65.16%;left: -0.23%;font-size: 62px;}
    &:nth-child(19) {top: 72.64%;left: 83.77%;font-size: 62px;}
    &:nth-child(20) {top: 87.55%;left: -0.22%;font-size: 38px;}
    &:nth-child(21) {top: 6.20%;left: 25.32%;font-size: 30px;}
    &:nth-child(22) {top: 2.90%;left: 3.07%;font-size: 19px;}
    &:nth-child(23) {top: 10.17%;left: 78.18%;font-size: 28px;}
    &:nth-child(24) {top: 25.29%;left: 11.64%;font-size: 38px;}
    &:nth-child(25) {top: 0.50%;left: 17.36%;font-size: 18px;}
    &:nth-child(26) {top: 29.51%;left: 3.21%;font-size: 32px;}
    &:nth-child(27) {top: 55.67%;left: 83.60%;font-size: 40px;}
    &:nth-child(28) {top: 53.10%;left: 0.33%;font-size: 34px;}
    &:nth-child(29) {top: 61.90%;left: 82.30%;font-size: 22px;}
    &:nth-child(30) {top: 88.58%;left: 82.25%;font-size: 41px;}
    &:nth-child(31) {top: 3.88%;left: 17.17%;font-size: 17px;}
    &:nth-child(32) {top: 22.04%;left: 2.00%;font-size: 24px;}
    &:nth-child(33) {top: 34.92%;left: -0.13%;font-size: 48px;}
    &:nth-child(34) {top: 49.53%;left: 88.65%;font-size: 23px;}
    &:nth-child(35) {top: 58.05%;left: 2.35%;font-size: 30px;}
    &:nth-child(36) {top: 71.90%;left: 0.95%;font-size: 27px;}
    &:nth-child(37) {top: 66.21%;left: 88.87%;font-size: 20px;}
    &:nth-child(38) {top: 79.08%;left: 0.92%;font-size: 32px;}
    &:nth-child(39) {top: 81.74%;left: 84.18%;font-size: 24px;}
    &:nth-child(40) {top: 47.52%;left: 2.21%;font-size: 26px;}
    &:nth-child(41) {top: 93.11%;left: 1.65%;font-size: 18px;}
    &:nth-child(42) {top: 31.33%;left: 77.42%;font-size: 40px;}
    &:nth-child(43) {top: 94.82%;left: 83.17%;font-size: 24px;}
  }

  @include w-from($screen-desktop-wide) {
    &:nth-child(4) {
      left: 47.2%;
      top: 5%;
      font-size: 36px;
    }
    &:nth-child(9) {
      left: 60.8%;
      top: 19.2%;
      font-size: 48px;
    }
    &:nth-child(20) {
      left: -0.2%;
      top: 88%;
      font-size: 50px;
    }
    &:nth-child(23) {
      left: 76%;
      top: 13.8%;
      font-size: 30px;
    }
    &:nth-child(29) {
      left: 85%;
      top: 60.5%;
      font-size: 42px;
    }
    &:nth-child(33) {
      left: 6%;
      font-size: 42px;
    }
    &:nth-child(34) {
      left: 85%;
      top: 48.5%;
      font-size: 60px;
    }
    &:nth-child(35) {
      top: 58%;
      font-size: 50px;
    }
    &:nth-child(36) {
      left: 5.7%;
      top: 71%;
      font-size: 28px;
    }
    &:nth-child(37) {
      font-size: 30px;
    }
    &:nth-child(39) {
      font-size: 34px;
    }
    &:nth-child(40) {
      font-size: 56px;
      left: 2%;
      top: 45.5%;
    }

  }
}
