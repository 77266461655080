@import "../vars";

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: -0.33px;
  font-weight: bold;

  .is__featured & {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.84px;

    @include w-to($screen-tablet) {
      &:not(:last-child) {
        margin-right: 36px;
      }
    }
  }

  &__title {
    font-family: $font-accent;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.2px;
    margin-bottom: 4px;
    display: inline-block;
    font-weight: normal;

    .is__featured & {
      font-size: 30px;
      line-height: 45px;
      letter-spacing: 0.3px;
    }
  }

  &__phrases {
    margin: 0;
    list-style: none;
    padding: 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .phrase {
    &:not(:last-child) {
      margin-bottom: 5px;

      .is__tagged & {
        margin-bottom: 4px;
      }
    }

    @include w-to($screen-tablet) {
      .is__featured & {
        max-width: 140px;
      }
    }
  }
}
