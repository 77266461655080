@import '../vars';

@keyframes loading-idle {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

.shareButton {
  position: relative;
  background: $link-color;
  color: #fff;
  width: 160px;
  height: 45px;
  padding: 3px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  @include w-to($screen-tablet) {
    width: 75px;
    height: 24px;

    svg {
      width: 90%;
    }
  }

  &:not(.is__ready) {
    pointer-events: none;

    svg {
      animation: loading-idle 1s linear infinite;
    }
  }

  &:not(.is__copied) {
    .iconShareText { display: block; margin-top: 2px; }
    .iconShareTextSuccess { display: none }

    .submittedFlag:hover &,
    &:hover {
      background: $link-hover-color;
    }
  }

  &.is__copied {
    .iconShareText { display: none }
    .iconShareTextSuccess { display: block }
  }

  &__caption {
    display: none;
    color: $link-color;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    width: 150%;
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: 5px;

    @include w-to($screen-laptop) {
      font-size: 10px;
      line-height: 11px;
      margin-top: 2px;
    }

    @include w-from($screen-tablet) {
      max-width: 130%;
    }

    .is__copied & {
      display: block;
    }
  }

  svg {
    fill: #fff;
  }
}
